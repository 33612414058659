<template>
  <div>
    <div class="topBanner1">
      <div class="countryStyle">
        {{ student_school }}
      </div>

      <div class="nameStyle">
        {{ student_name }}
      </div>

      <!-- <div class="showScoreStyle" @click="showScore()">View Results</div> -->
    </div>

    <div
      class="margin-left: 150px;margin-top: 50px;margin-right: 150px;background-color: #D9661F;"
    >
      <!-- <div class="lisbgimgview">
        <img
          src="../../../assets/newListBg.png"
          style="width: 100%; height: 100%; object-fit: fill"
        />
      </div> -->
      <div style="margin-left: 150px;margin-top: 10px;margin-right: 150px;color:#606266">
  
        <div style="padding:20px">
            <el-table :data="roomList">
                <el-table-column label="项目" prop="event" width="200"></el-table-column>
                <!-- <el-table-column label="准备开始时间" prop="prep_start_time"></el-table-column>
                <el-table-column label="准备结束时间" prop="prep_end_time"></el-table-column> -->
                <el-table-column label="正式演示时间" prop="prez_start_time"></el-table-column>
                <el-table-column label="房间号" prop="room" width="100"></el-table-column>
                <el-table-column label="腾讯会议ID" prop="meeting_room_code"></el-table-column>
                <el-table-column label="类型" prop="team_type" width="100"></el-table-column>
                <!-- <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="toView(scope.row._id.$id,scope.row.room,'',scope.row.team_type,scope.row.event)">进入准备室</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
      </div>

      <!-- <div class="my-events">
        <div
          v-for="(item, index) in my_events"
          :key="index"
          style="margin-top: 20px"
        >
          <div class="events-item">
            <div style="position: absolute; z-index: 1; width: 100%">
              <img
                src="../../../assets/event_bg.png"
                style="width: 100%; height: 140px; margin-top: 10px"
              />
            </div>
            <div style="z-index: 2; padding: 0px 36px">
              <div class="event_name">
                {{ item.event_name }}
              </div>
              <div
                style="
                  position: relative;
                  margin-top: 10px;
                  height: 30px;
                  display: flex;
                  flex-direction: row;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    Entry Time
                  </div>
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                      margin-left: 10px;
                    "
                  >
                    {{ formatSessionDate(item.event_start_time) }}
                  </div>

                  <div
                    style="
                      margin-left: 50px;
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    Start Time
                  </div>
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                      margin-left: 10px;
                    "
                  >
                    {{ formatSessionDate(item.event_start_time) }}
                  </div>
                </div>
                <div class="buttonStyle">
                  <div class="event-entry" @click="goScreen(item)">
                    {{ "&nbsp;&nbsp;Enter&nbsp;" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getTeamMembers, getPresentationRoomsByApplication,checkPresentationRoomOpen } from "../../../api/index";
import { getUserId, getProjectCode } from "../../../utils/store";
import { formatDate } from "../../../utils/date";
import "../../../assets/common/font.css";
export default {
  name: "SessionList",
  data() {
    return {
      my_events: [],
      student_name: "",
      student_school: "",
      roomList:[]
    };
  },
  mounted() {
    this.fetchData();
    this.getTeamMembers();
  },
  methods: {
    getTeamMembers() {
      getTeamMembers(getProjectCode(), getUserId()).then((res) => {
        var result = res.data.data;
        this.student_name = result.team_names.join(" ");
        this.student_school = result.country;
      });
    },
    fetchData() {
      let student_id = getUserId()
      getPresentationRoomsByApplication(student_id,getProjectCode()).then(res=>{
          console.log(res)
          if(res.data.code==0){
              this.roomList=res.data.data
          }
      })
    },
    formatDate(time){
        let date = new Date(time*1000)
        return formatDate(date,'Y-MM-dd hh:mm:ss')
    },
    toView(room_id,roomId,application_id,type,event_name){
        this.checkPrezRoomOpen(room_id,roomId,application_id,type,event_name)
    },
    checkPrezRoomOpen(room_id,roomId,application_id,type,event_name){
        
        checkPresentationRoomOpen(room_id).then(res=>{
            var open_data  = res.data
        // console.log(open_data)
            if (open_data.code == 0) {
              this.$router.push({
                  path:'/discussing',
                  query:{
                      roomId:roomId,
                      application_id:application_id,
                      event_name:event_name
                  }
              })
            }else{
                //TODO - 提示错误信息
                this.$message(open_data.msg)
            }
        })
        
    },
    showScore() {
      this.$router.push({
        path: "/award",
        query: {},
      });
    },
    formatSessionDate(time) {
      var time_1 = JSON.parse(time) * 1000;
      var date = new Date(time_1);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
    goScreen(data) {
      let url = this.$router.resolve({
        path: "/studentscreen",
        query: {
          roomId: data.event_meeting_room_ids[0],
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style scoped>
.buttonStyle {
  /* top: -20%; */
  transform: translateX(-30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  margin-top: -46px;
  /* height: 80px; */
  /* background-color: #D9661F; */
}
.event-entry {
  /* font-family: Montserrat-SemiBold; */
  cursor: pointer;
  padding: 2px 5px;
  /* width: 50px; */
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background: #e5ac11;
  /* text-align: center; */
  margin-top: 15px;
  /* border-radius: 5px; */
  /* border: 2px solid #6F69F7; */
  font-family: Montserrat-SemiBold;
}

.event-status {
  /* margin-top: px; */
  font-weight: 600;
  font-size: 18px;
  padding: 2px 5px;
  color: #00348b;
  background: white;
  /* border-radius: 5px; */
  border: 1px solid #00348b;
  font-family: Montserrat-SemiBold;
}

.event_name {
  font-family: Montserrat-SemiBold;
  font-size: 36px;
  font-weight: 600;
  color: #00348b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.events-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 80px;
}

.my-events {
  margin: 20px auto;
  width: 80%;
  /* margin-right: 150px; */
}

.student-session-title {
  font-weight: 600;
  color: #c4d7ff;
  font-size: 18px;
  font-family: Montserrat-SemiBold;
}

.session-title-1 {
  font-family: Montserrat-SemiBold;
  font-weight: 600;
  color: white;
  font-size: 36px;
}

.session-name {
  font-size: 28px;
  font-weight: 600;
  color: white;
  margin: 10px 0 0 45px;
  font-family: Montserrat-SemiBold;
}

.session-item {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 340px;
  height: 285px;
  background-image: url("../../../assets/session_nav_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.session-item-bottom {
  position: relative;
  height: 110px;
  margin-top: 30px;
  padding-left: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.session-time {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #f18601;
  font-weight: 600;
  font-family: Montserrat-SemiBold;
}

.sessionList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.type_label {
  font-size: 18px;
  margin-left: 10px;
}

.topBanner1 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #EDF2FF;
  background-size: cover;
  color: #00348b;
}
.bottomView {
  position: relative;
  /* z-index: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #d9661f;
  background-size: cover;
}
.lisbgimgview {
  position: absolute;
  z-index: 2;
  width: 265.25px;
  height: 220.5px;
  bottom: 0px;
}
.countryStyle {
  position: absolute;
  margin-top: -20px;
  margin-left: 150px;
  font-size: 20px;
  color: #00348b;
  height: 25px;
  font-family: Montserrat-SemiBold;
}

.nameStyle {
  position: absolute;
  margin-top: 25px;
  margin-left: 150px;
  font-size: 13px;
  color: #00348b;
  height: 25px;
  font-family: Montserrat-SemiBold;
}
.showScoreStyle {
  position: absolute;
  right: 200px;
  /* margin-right: 150px; */
  /* margin-top: 25px; */
  /* margin-left: 150px; */
  font-size: 13px;
  width: 120px;
  color: #00348b;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border: 1px solid #00348b;
  font-family: Montserrat-SemiBold;
}
</style>
